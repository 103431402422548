* {
  color: #333;
}

.trans-all {
  transition: all 200ms ease-in-out;
}

.text-7xl {
  font-size: 7rem;
}
.text-8xl {
  font-size: 8rem;
}

.text-9xl {
  font-size: 9rem;
}
.text-10xl {
  font-size: 10rem;
}
